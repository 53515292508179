<template>
	<div
		:class="['fixed', verticalPosition, horizontalPosition]"
		v-if="showNotification"
	>
		<TnNotificationInline
			:orientation="orientation"
			:dark="isDark"
			:type="type"
			:title="title"
			:closable="closable"
		>
			<RichText
				v-if="message"
				:text="message"
				:dark="isDark"
			/>
			<template #actions>
				<TnButton
					v-if="action"
					@click="triggerNotificationFn"
					class="margin-top-l"
					:dark="isDark"
					:primary="action.type === 'primary'"
					:secondary="action.type === 'secondary'"
					:responsive="true"
				>
					{{ action.text }}
				</TnButton>
			</template>
		</TnNotificationInline>
	</div>
</template>
<script lang="ts" setup>
const notification = useNotificationStore();
const {
	maxWidth,
	verticalPosition,
	horizontalPosition,
	orientation,
	showNotification,
	type,
	title,
	closable,
	message,
	action,
} = storeToRefs(notification);
const isDark = computed(() => {
	return !!usePageStore().page?.metadata?.dark;
});

const triggerNotificationFn = () => {
	if (notification.action && notification.action.fn) {
		notification.action.fn();
	}
	notification.reset(0);
};
</script>

<style lang="scss" scoped>
.fixed {
	position: fixed;
	z-index: 9999;
	flex-direction: column;
	gap: $spacing-s;
	width: 100%;
	max-width: v-bind(maxWidth);
}

.top {
	top: $spacing-m;
}

.bottom {
	bottom: $spacing-m;
}

.center {
	left: 50%;
	transform: translateX(-50%);
	align-items: center;
}

.right {
	right: $spacing-s;
	align-items: flex-end;
}

.left {
	bottom: $spacing-m;
	left: $spacing-s;
	align-items: flex-start;
}

.v-enter-active,
.v-leave-active {
	transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
	opacity: 0;
}
</style>
